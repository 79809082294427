.hero-container {
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
}

.hero-headline {
    font-size: 2.5rem;
    margin: 0;
    color: white;
}

.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;

    background: linear-gradient(to bottom,
            rgba(0, 0, 0, 0) 0%,
            /* Fully transparent at the top */
            rgba(0, 0, 0, 0.616) 50%,
            /* Dark in the middle */
            rgba(0, 0, 0, 0) 100%
            /* Fully transparent at the bottom */
        );
    background-position: center;
    /* Center the gradient horizontally */
}

.overlay p {
    margin-bottom: 0;
}