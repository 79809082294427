/* src/styles.css or relevant CSS file */

.image-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Default for larger screens */
    grid-auto-rows: auto;
    grid-auto-flow: dense;
    gap: 15px;
    padding: 0 20px 20px 20px;
}

.thumbnail {
    width: 100%; /* Fill the grid cell */
    height: auto; /* Maintain aspect ratio */
    cursor: pointer; /* Change cursor to pointer */
    transition: transform 0.2s; /* Animation effect */
    overflow: hidden; /* Ensure corners are rounded */
    position: relative; /* Position for child elements */
    display: flex; /* Flexbox to center the image */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    grid-row-end: span 1; /* Default span */
    grid-column-end: span 1; /* Default span */
}

/* Add specific classes to control spans */
.thumbnail.span-2 {
    grid-row-end: span 2; /* Vertical span of 2 */
}

.thumbnail.span-3 {
    grid-row-end: span 3; /* Vertical span of 3 */
}

.thumbnail.span-3-col {
    grid-column-end: span 3; /* Horizontal span of 2 */
}

.thumbnail.span-2-col {
    grid-column-end: span 2; /* Horizontal span of 2 */
}

.thumbnail.span-4 {
    grid-row-end: span 2; /* Vertical span of 2 */
    grid-column-end: span 2; /* Horizontal span of 2 */
}

.thumbnail.span-6 { 
    grid-row-end: span 4; /* Vertical span of 2 */
    grid-column-end: span 2; /* Horizontal span of 2 */
}

.thumbnail.span-2-row {
    grid-row-end: span 2; /* Horizontal span of 2 */
}

.thumbnail.span-4-row { 
    grid-row-end: span 2; /* Vertical span of 2 */
    grid-column-end: span 4; /* Horizontal span of 2 */
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Higher z-index for modal */
}

.modal-image {
    max-width: 90%;
    max-height: 90%;
    border-radius: 8px; /* Rounded corners for full image */
}

/* Optional: Add styles for hover effects */
.thumbnail::after {
    content: '';
    display: block;
    padding-bottom: 100%; /* Aspect ratio 1:1 */
}

.thumbnail img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; 
    height: 100%;
    object-fit: cover; /* Cover the thumbnail area */
}

#vigdis{
    object-fit: cover; /* Maintain aspect ratio while filling the grid cell */
    object-position: 60% 70%; /* Custom focal point */
}  

.arrow-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(236, 232, 255, 0.3); /* Subtle, semi-transparent dark background */
    border: none;
    border-radius: 50%; /* Circular shape for better pressing area */
    width: 40px; /* Slightly smaller width */
    height: 40px; /* Slightly smaller height */
    cursor: pointer; /* Pointer cursor for better UX */
    font-size: 20px; /* Discrete arrow size */
    color: white; /* White color for the arrow */
    display: flex; /* Flexbox for centering the arrow */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    transition: background-color 0.3s, transform 0.2s; /* Smooth transitions */
    z-index: 10; /* High z-index to keep it above other content */
    opacity: 0.6; /* Lower opacity for a more subtle look */
  }
  
  .arrow-button.left {
    left: 15px; /* Position on the left side */
  } 
  
  .arrow-button.right {
    right: 15px; /* Position on the right side */
  }
  
  .arrow-button:hover {
    background-color: rgba(0, 0, 0, 0.5); /* Slightly more visible background on hover */
    transform: translateY(-50%) scale(1.05); /* Small scale up on hover */
    opacity: 0.8; /* Increase opacity on hover */
  }
  
  .arrow-button:focus {
    outline: none; /* Remove default outline on focus */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Subtle shadow effect */
  }
  

/* Media query for tablets and small screens */
@media (max-width: 1024px) {
    .image-gallery {
        grid-template-columns: repeat(auto-fill, minmax(80px, 1fr)); /* Adjust for smaller screens */
    }
}