.description {
    text-align: center;
    margin: 20px 0;
    font-size: 1.2em;
    color: #333; /* Adjust the color as needed */
}

.arrow-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.arrow {
    font-size: 2em;
    animation: bounce 1.5s infinite;
    color: #333; /* Adjust the color as needed */
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}
