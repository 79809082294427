
body{
  animation: gradientShift 15s ease infinite;
  background: linear-gradient(45deg, #010014, #0c0014, #001724, #0b0018);
  background-size: 400% 100%; 
}

h1, h2, h3, h4, h5, h6, p, span, a, li, div {
  color: white;
}

h1{
  text-align: center;
}

.App{
  display: flex;
  flex-direction: column;
  justify-content: center;
}